import { LinkProps } from 'react-router-dom'
import { Props as IconProps } from '../../../components/elements/Icon'
import { Cards } from '../../../hooks/queryHooks/useGetCards'
import { BankCard } from '../../../integrations/allkort/v1/schemas/Card'
import { Urls } from '../../urls'

export type MenuItem = {
  classes?: string[]
  icon?: IconProps
  link?: LinkProps & { state?: unknown }
  onClick?: () => void
  requiresBankId?: boolean
  title: string
  shouldBeLink?: boolean
  url?: string
}

export const menuItems = (cards: Cards, bankcards: BankCard[]): MenuItem[] => {
  return [
    cards!.smartCards.length > 0 ||
    cards!.tankCards.length > 0 ||
    (bankcards && bankcards!.length > 0)
      ? {
          classes: [],
          icon: {
            name: 'file-invoice-dollar',
            primaryColor: 'pink500',
            secondaryColor: 'blue400'
          },
          title: 'Mina köp',
          url: Urls.fakturorKontobesked
        }
      : null,
    cards!.smartCards.length > 0 ||
    cards!.tankCards.length > 0 ||
    bankcards!.length > 0
      ? {
          classes: [],
          icon: {
            name: 'credit-card',
            primaryColor: 'red400',
            secondaryColor: 'turquoise500'
          },
          title: 'Mina kort',
          url: Urls.kort
        }
      : null,
    {
      classes: [],
      icon: {
        name: 'user-circle',
        primaryColor: 'red400',
        secondaryColor: 'white'
      },
      title: 'Profil',
      url: Urls.profil
    }
    // Filter out falsy values
  ].filter((v) => v) as MenuItem[]
}

export const cardSubMenuItems = (
  cards: Cards,
  bankcards: BankCard[]
): MenuItem[] => {

  return [
    cards!.tankCards.length > 0
      ? {
          classes: [],
          icon: {
            name: 'file-invoice-dollar',
            primaryColor: 'pink500',
            secondaryColor: 'blue400'
          },
          title: 'Tankkort',
          url: Urls.tankkortSubheader
        }
      : null,

    cards!.smartCards.length > 0
      ? {
          classes: [],
          icon: {
            name: 'credit-card',
            primaryColor: 'red400',
            secondaryColor: 'turquoise500'
          },
          title: 'Smartkort',
          url: Urls.smartkortSubheader
        }
      : null,
    bankcards && bankcards!.length > 0
      ? {
          classes: [],
          icon: {
            name: 'credit-card',
            primaryColor: 'red400',
            secondaryColor: 'turquoise500'
          },
          title: 'Bankkort',
          url: Urls.bankkortSubheader
        }
      : null
    // Filter out falsy values
  ].filter((v) => v) as MenuItem[]
}

export const profileSubMenuItems = (): MenuItem[] => {
  return [
    {
      classes: [],
      icon: {
        name: 'file-invoice-dollar',
        primaryColor: 'pink500',
        secondaryColor: 'blue400'
      },
      title: 'Inställningar',
      url: Urls.profil
    },
    {
      classes: [],
      icon: {
        name: 'credit-card',
        primaryColor: 'red400',
        secondaryColor: 'turquoise500'
      },
      title: 'Hantera användare',
      url: Urls.hanteraAnvandareSubMenu
    }
    // Filter out falsy values
  ].filter((v) => v) as MenuItem[]
}

export const createSideMenu = (user: {
  name: string
  isCompany: boolean
}): MenuItem[] => {
  return [
    {
      classes: ['Button--Contact'],
      icon: {
        name: 'phone',
        primaryColor: 'purple',
        secondaryColor: 'pink500'
      },
      title: 'Kundservice',
      url: Urls.kontakt
    },
    {
      classes: ['d-flex justify-content-start align-items-center'],
      icon: {
        name: 'user'
      },
      url: Urls.profil,
      title: user.name
    },
    {
      classes: ['Button', 'Button--Logout'],
      icon: {
        name: 'sign-out-alt',
        primaryColor: 'red400',
        secondaryColor: 'pink500'
      },
      title: 'Logga ut',
      url: Urls.loggaut
    }
  ]
}
