/* eslint-disable no-nested-ternary */
import React, { Fragment, useState } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import { useGetCards } from '../../../hooks/queryHooks/useGetCards'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import { usePutCustomer } from '../../../hooks/queryHooks/usePutCustomer'
import { EmailType } from '../../../integrations/allkort/v1/enums/EmailType'
import { Email } from '../../../integrations/allkort/v1/schemas/Email'
import { currentUserHasRight } from '../../../utils/currentUserHasRight'
import Link from '../../elements/Link'
import PaymentMethodBadge from '../../elements/PaymentMethodBadge'
import RightsButton from '../../elements/RightsButton'
import { useCustomerStatus } from '../../hocs/GetCustomerStatus'
import ProfileBox from './ProfileBox'
import ProfileSettingsModal from './ProfileSettingsModal'

export type DeliveryType = 'Email' | 'PaperMailing'

type Props = {}
let emailId = 0
export const CustomerProfileBalance: React.FC<Props> = () => {
  const { data: customer } = useGetCustomer()
  const { customerAllowedToWriteBool } = useCustomerStatus( customer );

  const { data: cards } = useGetCards()
  const updateCustomerMutation = usePutCustomer()

  const canWrite =
    currentUserHasRight('CustomerWrite') && currentUserHasRight('EmailWrite')

  const invoiceStatementEmail =
    customer!.emails.find(
      (email) => email.emailTypeId === EmailType.InvoiceStatement
    )?.emailAddress ?? ''

  const currentDeliveryType = customer!
    .statementMailingDeliveryType as DeliveryType

  const address =
    customer!.address1 + ', ' + customer!.postalCode + '  ' + customer!.city

  const [errorMessage, setErrorMessage] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newDeliveryType, setNewDeliveryType] = useState(
    currentDeliveryType || 'PaperMailing'
  )
  const [newEmail, setNewEmail] = useState('')
  const [saveChanges, setSaveChanges] = useState(false)

  const getSaveButtonState = () => {
    const hasTankCards = cards!.tankCards.length !== 0
    const mailInvoice = customer!.invoiceMailingDeliveryType === 'Email'

    if (errorMessage === '') {
      if (newDeliveryType === 'Email' && newEmail === '') {
        return 'disabled'
      } else if (currentDeliveryType !== newDeliveryType) {
        return 'enabled'
      } else if (
        newDeliveryType === 'Email' &&
        newEmail !== invoiceStatementEmail
      ) {
        return 'enabled'
      } else if (!hasTankCards && !mailInvoice) {
        return 'enabled'
      }
    }
    return 'disabled'
  }

  const handleBack = () => {
    setSaveChanges(false)
  }

  const handleCancel = () => {
    setErrorMessage('')
    setIsModalOpen(false)
    setNewDeliveryType(currentDeliveryType || 'Email')
    setSaveChanges(false)
  }

  const handleConfirm = () => {
    setIsModalOpen(false)
    setSaveChanges(false)
    if (newDeliveryType === 'Email') {
      let newEmails: Email[] = [...customer!.emails]

      if (!customer!.isCompany) {
        newEmails = [
          ...customer!.emails.filter(
            (email) => email.emailTypeId !== 'InvoiceStatement'
          )
        ]
      }

      if (
        !newEmails.find((email) => email.emailTypeId === 'InvoiceStatement')
      ) {
        newEmails.push({
          emailAddress: newEmail,
          emailTypeId: 'InvoiceStatement',
          emailId: emailId++,
          sortOrder: 0
        })
      }

      updateCustomerMutation.mutate({
        emails: newEmails,
        statementMailingDeliveryType: 'Email'
      })
    } else {
      updateCustomerMutation.mutate({
        statementMailingDeliveryType: 'PaperMailing'
      })
    }
  }

  const handleEmailChange = (event: React.ChangeEvent): void => {
    const element = event.target as HTMLInputElement
    const email = element.value

    const newErrorMessage =
      newDeliveryType === 'Email' && !isEmail(email)
        ? 'Mailadressen ser inte rätt ut.'
        : ''
    setErrorMessage(newErrorMessage)
    setNewEmail(email)
  }

  const handleOpenModal = () => {
    let email: string = ''

    if (invoiceStatementEmail) {
      email = invoiceStatementEmail
    }

    setIsModalOpen(true)
    setNewEmail(email)
  }

  const handleSave = () => {
    const newSaveChanges = !errorMessage

    if (newSaveChanges) {
      setSaveChanges(newSaveChanges)
    }

    return newSaveChanges
  }

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement
    const deliveryType = element.value as DeliveryType

    setNewDeliveryType(deliveryType)
  }

  const saveButtonState = getSaveButtonState()

  const hasTankCards = cards!.tankCards.length !== 0

  const invoiceEmails = customer!.emails.filter(
    (email) => email.emailTypeId === EmailType.InvoiceStatement
  )

  return (
    <div>
      {isModalOpen && !saveChanges && (
        <ProfileSettingsModal
          onBack={handleBack}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          onSave={handleSave}
          saveButtonState={saveButtonState}
          saved={saveChanges}
        >
          <div className="Modal__Section">
            <h5>{'Kontobesked - Smartkort'}</h5>
            <div
              className="ProfileSettings__RadioGroup"
              onChange={handleToggle}
            >
              <div className="ProfileSettings__RadioRow">
                <label className="ProfileSettings__RadioGroupLabel">
                  <input
                    defaultChecked={newDeliveryType === 'Email'}
                    name="newDeliveryType"
                    type="radio"
                    value="Email"
                  />
                  {' Mail'}
                </label>
                {currentDeliveryType === 'Email' && (
                  <PaymentMethodBadge iconOnly />
                )}
              </div>
              <p className="ProfileSettings__BalanceModal">
                {'Ett Kontobesked skickas till dig varje månad via mail.'}
              </p>

              <div className="ProfileSettings__RadioRow">
                <label className="ProfileSettings__RadioGroupLabel">
                  <input
                    defaultChecked={newDeliveryType === 'PaperMailing'}
                    name="newDeliveryType"
                    type="radio"
                    value="PaperMailing"
                  />
                  {' Brev'}
                </label>
                {currentDeliveryType === 'PaperMailing' && (
                  <PaymentMethodBadge iconOnly />
                )}
              </div>
              <p
                className="ProfileSettings__BalanceModal"
                style={{ marginBottom: 0 }}
              >
                {'Ett Kontobesked skickas till dig varje månad via post.'}
              </p>
            </div>
          </div>
          {(newDeliveryType === 'Email' && (!hasTankCards || !invoiceEmails)) ||
            (newDeliveryType === 'Email' && !customer!.isCompany && (
              <div className="Modal__Section">
                {(customer!.isCompany ? invoiceEmails.length === 0 : true) ? (
                  <>
                    <div className="ProfileSettings__Subheading">
                      <b>{'Mail'}</b>
                    </div>
                    <div>
                      <input
                        className="ProfileSettingsModal__InputField"
                        onChange={handleEmailChange}
                        value={newEmail}
                      />
                      <p className="ProfileSettingsModal__ErrorMessage">
                        {errorMessage}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p style={{ marginBottom: 0 }}>
                      {
                        'Dina mailfakturor kommer skickas till följande adresser:'
                      }
                    </p>
                    {invoiceEmails.map((invoiceEmail) => (
                      <Fragment key={invoiceEmail.emailId}>
                        <b>{invoiceEmail.emailAddress}</b>
                        <br />
                      </Fragment>
                    ))}
                    {customer!.isCompany && (
                      <small>
                        {
                          'Dessa mailadresser kan redigeras under sektionen "Mailadresser" i '
                        }
                        <Link
                          href="/profil#emails"
                          onClick={() => {
                            handleCancel()
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                          }}
                          styled
                        >
                          {'din profillll'}
                        </Link>
                      </small>
                    )}
                  </>
                )}
              </div>
            ))}
          {customer!.isCompany &&
            newDeliveryType === 'Email' &&
            hasTankCards &&
            invoiceEmails && (
              <div className="Modal__Section">
                <p style={{ marginBottom: 0 }}>
                  {'Kontobesked skickas till: '}
                </p>
                <p className="BreakWord">
                  {invoiceEmails.map((invoiceEmail) => (
                    <Fragment key={invoiceEmail.emailId}>
                      <b>{invoiceEmail.emailAddress}</b>
                      <br />
                    </Fragment>
                  ))}
                </p>
                <p>
                  {customer!.isCompany && (
                    <small>
                      {
                        'Dessa mailadresser kan redigeras under sektionen "Mailadresser" i '
                      }
                      <Link
                        href="/profil#emails"
                        onClick={() => {
                          handleCancel()
                          window.scrollTo({ top: 0, behavior: 'smooth' })
                        }}
                        styled
                      >
                        {'din profil'}
                      </Link>
                    </small>
                  )}
                </p>
              </div>
            )}
          {newDeliveryType === 'PaperMailing' && (
            <div className="Modal__Section">
              <div className="ProfileSettings__Subheading">
                <b>{'Adress'}</b>
              </div>
              <p>{address}</p>
              <p>
                <small>
                  {'För att ändra din adress behöver du '}
                  <Link href="/kontakt" styled>
                    {'kontakta oss.'}
                  </Link>
                </small>
              </p>
            </div>
          )}
        </ProfileSettingsModal>
      )}
      {isModalOpen &&
        saveChanges &&
        (newDeliveryType === 'Email' || newDeliveryType === 'PaperMailing') && (
          <ProfileSettingsModal
            onBack={handleBack}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onSave={handleSave}
            saved={saveChanges}
          >
            <div className="Modal__Section">
              <h5>{'Bekräfta dina ändringar'}</h5>
              {newDeliveryType === 'Email' ? (
                <>
                  {currentDeliveryType === 'Email' ? (
                    <p>
                      {'Är du säker på att du vill ändra mailadress till '}
                      <b>{newEmail}</b>
                      {'?'}
                    </p>
                  ) : (
                    <p>
                      {
                        'Är du säker på att du vill ändra till Kontobesked via mail?'
                      }
                    </p>
                  )}
                </>
              ) : (
                <p>
                  {
                    'Är du säker på att du vill ändra till Kontobesked via brev?'
                  }
                </p>
              )}
            </div>
          </ProfileSettingsModal>
        )}
      <h4 id="statement-settings">{'Kontobesked - Smartkort'}</h4>
      <ProfileBox>
        {invoiceEmails.length > 0 ? (
          `Kontobesked skickas varje månad via ${
            currentDeliveryType === 'PaperMailing'
              ? 'brev till:'
              : 'mail till följande adresser:'
          }`
        ) : (
          <i>{'Du har inget leveranssätt valt för dina kontobesked.'}</i>
        )}
        {(address || customer!.emails) && (
          <p>
            <b className="BreakWord">
              {currentDeliveryType === 'PaperMailing'
                ? address.split(',').reduce((acc, part, index) => {
                    if (index === 0) {
                      acc[0] = part + ','
                      acc[1] = <br key="break" />
                    } else {
                      acc.push(part)
                    }
                    return acc
                  }, [] as unknown[])
                : customer!.isCompany
                ? customer!.emails
                    .filter(
                      (email) =>
                        email.emailTypeId === EmailType.InvoiceStatement
                    )
                    .map((email) => (
                      <Fragment key={email.emailAddress}>
                        <span>{email.emailAddress}</span>
                        <br />
                      </Fragment>
                    ))
                : invoiceStatementEmail}
            </b>
          </p>
        )}

        <RightsButton
          className="PaymentOptions__Badge"
          disabled={customer!.isFrozen || !canWrite}
          onClick={handleOpenModal}
          type="button"
          userRights="CustomerWrite"
          customerAllowedToWrite={customerAllowedToWriteBool}
        >
          <span className="PaymentOptions__BadgeText">
            {invoiceEmails.length === 0
              ? 'Lägg till leveranssätt'
              : 'Redigera Kontobesked'}
          </span>
        </RightsButton>
      </ProfileBox>
    </div>
  )
}
