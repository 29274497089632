import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useGetAuthenticationRights } from '../../hooks/queryHooks/useGetAuthenticationRights'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import { useGetUserProfiles } from '../../hooks/queryHooks/useGetUserProfiles'
import { UserProfileSortingString } from '../../integrations/allkort/v1/repositories/UserProfileRepository'
import { UserProfile } from '../../integrations/allkort/v1/schemas/UserProfile'
import Icon from '../elements/Icon'
import Loader from '../elements/Loader'
import Modal from '../elements/Modal'
import RightsButton from '../elements/RightsButton'
import { useCustomerStatus } from '../hocs/GetCustomerStatus'
import { ConfirmationModal, ConfirmationModalData } from './ConfirmationModal'
import Pagination from './Pagination'
import { UserProfileCreateModal } from './UserProfileCreateModal'
import { UserProfileEditModal } from './UserProfileEditModal'
import { UserProfileTableBody } from './UserProfileTableBody'
import { UserProfileTableHead } from './UserProfileTableHead'

type UserProfileTableProps = {}

/**
 * Save page number in module scope to restore the page
 * when navigating back to the page during the same session.
 */
let persistentPageNumber: number | null = null
let persistentSortingString: UserProfileSortingString | null = null

const UserProfileTable: React.FC<UserProfileTableProps> = () => {
  const isInitialRender = useRef(true)
  const navigate = useNavigate()
  const location = useLocation()

  const { data: customer } = useGetCustomer()

  const { customerAllowedToWriteBool } = useCustomerStatus( customer );

  const { data: authenticationRights } = useGetAuthenticationRights()

  const [searchParams, setSearchParams] = useSearchParams()

  const [isCreateUserModalOpen, setIsCreateUserModalOpen] =
    useState<boolean>(false)
  const [confirmationModal, setConfirmationModal] =
    useState<ConfirmationModalData | null>(null)

  const currentPage = searchParams.get('page')
    ? parseInt(searchParams.get('page') as string)
    : persistentPageNumber || 1

  const currentSorting: UserProfileSortingString | null = searchParams.get(
    'sorting'
  )
    ? (searchParams.get('sorting') as UserProfileSortingString)
    : persistentSortingString || null

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false

      const shouldSetSortingParam =
        !searchParams.get('sorting') && persistentSortingString

      const shouldSetPageParam =
        !searchParams.get('page') &&
        typeof persistentPageNumber === 'number' &&
        persistentPageNumber > 1

      if (shouldSetPageParam) {
        searchParams.set('page', persistentPageNumber!.toString())
      } else if (!shouldSetPageParam && searchParams.get('page')) {
        persistentPageNumber = parseInt(searchParams.get('page') as string)
      }

      if (shouldSetSortingParam) {
        searchParams.set('sorting', persistentSortingString!)
      } else if (!shouldSetPageParam && searchParams.get('sorting')) {
        persistentSortingString = searchParams.get(
          'sorting'
        ) as UserProfileSortingString
      }

      if (shouldSetPageParam || shouldSetSortingParam) {
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        navigate(newUrl, { replace: true })
      }
    } else if (
      searchParams.toString() === '' &&
      (persistentPageNumber !== null || persistentSortingString !== null)
    ) {
      /**
       * Reset persistent state if the user clicks the link to the same
       * page and resets the search params
       */
      persistentPageNumber = null
      persistentSortingString = null
      setSearchParams(searchParams)
    }
  }, [location])

  const { data: userProfilesPaged, isFetching } = useGetUserProfiles({
    fetchQuery: {
      PageIndex: currentPage,
      sortingParameters: currentSorting ?? undefined
    }
  })

  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
  const selectedUser: UserProfile | null =
    selectedUserId === null || !userProfilesPaged
      ? null
      : (userProfilesPaged.payload[selectedUserId] as UserProfile)

  const handleNavigation = (pageNr: number) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

    searchParams.set('page', pageNr.toString())
    persistentPageNumber = pageNr
    setSearchParams(searchParams)
  }

  const handleSortingChange = (
    newSortingString: UserProfileSortingString | null
  ) => {
    if (newSortingString === null) {
      searchParams.delete('sorting')
      persistentSortingString = newSortingString
      setSearchParams(searchParams)
      return
    }

    searchParams.set('sorting', newSortingString)
    persistentSortingString = newSortingString
    setSearchParams(searchParams)
  }

  if (!userProfilesPaged || !authenticationRights) {
    return <Loader text="Laddar användare..." />
  }

  return (
    <div className="user-profile-table">
      <RightsButton
        className="user-profile-table__create-user-button"
        onClick={() => setIsCreateUserModalOpen(true)}
        userRights="UserProfilesWrite"
        customerAllowedToWrite={customerAllowedToWriteBool}
      >
        <Icon name="plus" />
        {'Skapa ny användare'}
      </RightsButton>

      <div className="user-profile-table__table-wrapper">
        <AnimatePresence initial={false}>
          {isFetching && (
            <motion.span
              animate={{ opacity: 1 }}
              className="user-profile-table__loader"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              key="loader"
            >
              <Loader noPadding text="Laddar användare..." />
            </motion.span>
          )}

          <table className="user-profile-table__table">
            <UserProfileTableHead handleSortingChange={handleSortingChange} />
            <UserProfileTableBody
              authenticationRights={authenticationRights}
              setConfirmationModal={setConfirmationModal}
              setSelectedUserId={setSelectedUserId}
              userProfiles={userProfilesPaged?.payload as UserProfile[]}
            />
          </table>
        </AnimatePresence>

        {selectedUser && (
          <Modal onClose={() => setSelectedUserId(null)}>
            <UserProfileEditModal
              authenticationRights={authenticationRights}
              closeModal={() => setSelectedUserId(null)}
              selectedUser={selectedUser}
              setConfirmationModal={setConfirmationModal}
            />
          </Modal>
        )}
        {isCreateUserModalOpen && (
          <Modal onClose={() => setIsCreateUserModalOpen(false)}>
            <UserProfileCreateModal
              authenticationRights={authenticationRights}
              closeModal={() => setIsCreateUserModalOpen(false)}
              setConfirmationModal={setConfirmationModal}
            />
          </Modal>
        )}
        {confirmationModal?.isOpen && (
          <Modal onClose={() => setConfirmationModal(null)}>
            <ConfirmationModal confirmationModal={confirmationModal} />
          </Modal>
        )}
      </div>

      {userProfilesPaged.maxPages > 1 && (
        <Pagination
          currentPage={currentPage}
          maxPages={userProfilesPaged.maxPages}
          onChange={handleNavigation}
        />
      )}
    </div>
  )
}

export default UserProfileTable
