import { useState } from "react";

// Checks the customer state if it is not set that means `customer.customerState` is `null`
function initializeCustomerAllowedToWrite(customerStatusData: { customerState: null; }) {
	if ( null === customerStatusData?.customerState || undefined === customerStatusData?.customerState ) {
		return true;
	}
	return false;
}
// Takes the data from `useGetCustomer()`
export function useCustomerStatus(customer: any) {
	// Run once and set the correct state from the beginnning as initial state.
	const [customerAllowedToWriteBool, setCustomerAllowedToWriteBool] = useState(initializeCustomerAllowedToWrite(customer));

	return {customerAllowedToWriteBool};
}