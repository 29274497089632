import React from 'react';
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer';
import RightsButton from '../elements/RightsButton';
import { useCustomerStatus } from '../hocs/GetCustomerStatus';
import SmartCardView from '../views/SmartCard/SmartCardView';

type SmartCardPageProps = {}

const SmartCardPage: React.FC<SmartCardPageProps> = () => {
	const { data: customer } = useGetCustomer()
	const hasBankCards = customer!.bankCardCustomer === true
	const { customerAllowedToWriteBool } = useCustomerStatus( customer );

	// Checks if the customer has any status it is blocked and only bankcard view is allowed
	if ( ! customerAllowedToWriteBool ) {
		return ( 
			<>
				<div className="Row Row--JustifyLeft">
					<div className="Row__Inner">
						<div className="Column Column--JustifyStart Column--Lg6">
							<div className="ContentBox">
								<div className="ContentBox__Content">
									<h3>{'Smartkort'}</h3>
									<p>{`Just nu har du inte tillgång till smartkortsöversikten,
									vänligen kontakta kundservice på `} <a href="tel:011280000">011-28 00 00</a>{` för mer information.`}</p>
									{ !! hasBankCards && (
										<RightsButton
										className="Cards_bankcard"
										style={{ margin: 0 }}
										text="Till bankkortsöversikt"
										url="/fakturor-kontobesked/bankcard"
										userRights="Superuser"
										customerAllowedToWrite={true}
									/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	} else {
		return (
			<>
				<div className="Row">
					<div className="Row__Inner">
						<div className="Column Column--JustifyStart Column--Lg6">
							<div className="ContentBox">
								<div className="ContentBox__Content">
									<h1>{'Smartkort'}</h1>
									<p>
									{
										'Du måste ladda ditt kort innan det går att tanka och det tar upp till tre bankdagar innan pengarna registrerats och kan användas. Du laddar med ditt personliga OCR-nummer som alltid är samma. Brytdatum för kontobesked är alltid natten mellan den 15:e och den 16:e varje månad.'
									}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<SmartCardView />
			</>
		)
	}
}

export default SmartCardPage
