import classNames from 'classnames'
import * as DateFns from 'date-fns'
import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { CardModel } from '../../domain/models/CardModel'
import * as LocalStorageService from '../../domain/services/LocalStorageService'
import { useGetBankCards } from '../../hooks/queryHooks/useGetBankCards'
import { useGetCards } from '../../hooks/queryHooks/useGetCards'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import { useUserRights } from '../../hooks/useUserRights'
import { BankCard } from '../../integrations/allkort/v1/schemas/Card'
import { Urls } from '../../legacy/urls'
import Button from '../elements/Button'
import Checkbox from '../elements/Checkbox'
import Icon from '../elements/Icon'
import Label from '../elements/Label'
import { NeedsExtAuth } from '../elements/NeedsExtAuth'
import RightsButton from '../elements/RightsButton'
import { useCustomerStatus } from '../hocs/GetCustomerStatus'
import CardGrid from '../views/CardGrid'
import CardTable from '../views/CardTable'
import CardsList from '../views/CardsList'

const DEFAULT_TO_LIST_VIEW_MIN_CARD_COUNT = 4
const OLD_DISABLED_CARD_AGE_MONTHS = 3

type BaseFilterInputs = {
	vehicleSpecification: 'all' | 'active' | 'disabled'
	cardStatus: 'all' | 'active' | 'disabled'
	cardType: 'all' | 'smartcard' | 'creditcard' | 'bankcard'
}
const BASE_FILTER_INITIAL_VALUE: BaseFilterInputs = {
	vehicleSpecification: 'all',
	cardStatus: 'all',
	cardType: 'all'
}

type Props = {}

const CardsPage: React.FC<Props> = () => {
	const cardsQuery = useGetCards()
	const bankCardQuery = useGetBankCards()
	const customerQuery = useGetCustomer()
	const isBankIdUser = useIsBankIdUser()
	const { userHasRight } = useUserRights()
	const canWrite = userHasRight('CardsWrite')

	const [baseFilterInputs, setBaseFilterInputs] = useState<BaseFilterInputs>(
    BASE_FILTER_INITIAL_VALUE
	)

	const [dropDownCardListValue, setDropDownCardListValue] = useState('none')
	const [searchTerm, setSearchTerm] = useState('')
	const [showListView, setShowListView] = useState(false)
	const [showOldCards, setShowOldCards] = useState(false)

	const filterCards = (
		cards: (CardModel | BankCard)[]
	): (CardModel | BankCard)[] => {
    // Dropdown list filter:
    if (dropDownCardListValue !== 'none') {
		return cards.filter((value: CardModel | BankCard) => {
			if ('token' in value) {
				return value.maskedPan === dropDownCardListValue
			} else {
				return value.cardNo === dropDownCardListValue
			}
		})
    }

    // Search filter:
    cards = cards.filter((value: CardModel | BankCard) => {
	if (!searchTerm) {
        return true
	}

	if ('token' in value) {
        return (
			value.maskedPan.toString().toLowerCase().includes(searchTerm) ||
			value.maskedPan.toLowerCase().includes(searchTerm)
        )
	} else {
        return (
			value.cardNo.toLowerCase().includes(searchTerm) ||
			value.cardReference1.toLowerCase().includes(searchTerm) ||
			value.cardReference2.toLowerCase().includes(searchTerm)
        )
	}
})

    // Type filter:
    cards = cards.filter((value: CardModel | BankCard) => {
	switch (baseFilterInputs.cardType) {
        case 'smartcard':
			if ('token' in value) {
				return false
			} else {
				return value.isSmartCard
			}
        case 'creditcard':
			if ('token' in value) {
				return false
			} else {
				return !value.isSmartCard
			}
        case 'bankcard':
			if ('token' in value) {
				return true
			} else {
				return false
			}
        default:
			return true
	}
})
    // Status filter:
    cards = cards.filter((value: CardModel | BankCard) => {
		switch (baseFilterInputs.cardStatus) {
			case 'active':
				if ('token' in value) {
					return true
				} else {
					return !value.disabled
			}
			case 'disabled':
				if ('token' in value) {
					return false
				} else {
				return value.disabled
				}
			default:
				return true
			}
		})

    // Vehicle filter:
    cards = cards.filter((value: CardModel | BankCard) => {
	switch (baseFilterInputs.vehicleSpecification) {
        case 'active':
			if ('token' in value) {
				return false
			} else {
				return value.requiresVehicle && value.requiresMilage
			}
        case 'disabled':
			if ('token' in value) {
				return false
			} else {
				return !value.requiresVehicle || !value.requiresMilage
			}
        default:
			return true
	}
})

    // Old disabled card filter:
    cards = cards.filter((value: CardModel | BankCard) => {
		if (showOldCards) {
			return true
		}
		if ('cardNo' in value) {
			if (value.disabledDate !== null && value.disabled) {
				const threeMonthsBack = DateFns.addMonths(
				new Date(),
				-OLD_DISABLED_CARD_AGE_MONTHS
				)
				return value.disabledDate >= threeMonthsBack
			}
		}

		return true
    })

    return cards
	}

const cardsList =
cardsQuery.data && bankCardQuery!.data
	? [
		...cardsQuery.data.smartCards,
		...cardsQuery.data.tankCards,
		...bankCardQuery!.data
	]
	: []

const filteredCards = [...filterCards(cardsList)]

const handleBaseFilterChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
	const value = e.currentTarget
		.value as BaseFilterInputs[keyof BaseFilterInputs]
	const name = e.currentTarget.name as keyof BaseFilterInputs

    setDropDownCardListValue('none')
    setBaseFilterInputs((prev) => ({ ...prev, [name]: value }))
}

const handleOnChangeDropdownCard = (event: React.ChangeEvent) => {
	const element = event.target as HTMLInputElement
	if (element.value !== dropDownCardListValue) {
		setDropDownCardListValue(element.value)
		setBaseFilterInputs(BASE_FILTER_INITIAL_VALUE)
		setSearchTerm('')
	}
}

const handleOnChangeSearchInput = (event: React.ChangeEvent) => {
	const element = event.target as HTMLInputElement
	const newSearchTerm = element.value.toLowerCase()

	setDropDownCardListValue('none')
	setSearchTerm(newSearchTerm)
}

const handleOnChangeFilterOldCard = () => {
	setShowOldCards((oldState) => !oldState)
}

const handleOnChangeListView = () => {
    const newShowListView = !showListView

    setShowListView(newShowListView)
    LocalStorageService.set(
		'qstar.mypages.cardView',
		newShowListView ? 'LIST' : 'GRID'
    )
}

const shouldInitiallyShowListView = () => {
    const cardCount = cardsList.length
    const storedCardView = LocalStorageService.get('qstar.mypages.cardView')
    const shouldShowListView =
	storedCardView === undefined
        ? cardCount >= DEFAULT_TO_LIST_VIEW_MIN_CARD_COUNT
        : storedCardView === 'LIST'

    return shouldShowListView
}

useEffect(() => {
    if (!cardsQuery.isLoading) {
		setShowListView(shouldInitiallyShowListView())
    }
}, [cardsQuery.isLoading])

const hasSmartCards = cardsQuery.data!.smartCards.length > 0
const hasCreditCards = cardsQuery.data!.tankCards.length > 0
const hasBankCards = bankCardQuery!.data!.length > 0

const getHeaderText = (
    hasSmartCards: boolean,
    hasCreditCards: boolean,
    hasBankCards: boolean
): string => {
    if (!hasSmartCards && hasCreditCards && !hasBankCards) {
		return 'Översikt för tankkort'
    }
    if (hasSmartCards && !hasCreditCards && !hasBankCards) {
		return 'Översikt för smartkort'
    }
    if (hasBankCards && !hasCreditCards && !hasSmartCards) {
		return 'Översikt för bankkort'
    }
    if (hasBankCards && hasCreditCards && !hasSmartCards) {
		return 'Översikt för tank- och bankkort'
    }
    if (hasBankCards && !hasCreditCards && hasSmartCards) {
		return 'Översikt för smart- och bankkort'
    }
    if (!hasBankCards && hasCreditCards && hasSmartCards) {
		return 'Översikt för tank- och smartkort'
    }
    return 'Översikt för tank-, smart- och bankkort'
}

const getInfoText = (
    hasSmartCards: boolean,
    hasCreditCards: boolean,
    hasBankCards: boolean
): string => {
    if (!canWrite && customerQuery.data?.isCompany) {
		return 'Kontakta kundsupport för att beställa nya kort eller ersättningskort.'
    }

    if (!isBankIdUser) {
		return (
        'Inloggning via BankID krävs för att beställa nya kort, ' +
        'ersättningskort eller byta namn/pinkod på kort. '
		)
    }

    if (hasBankCards && !hasCreditCards && !hasSmartCards) {
		return 'På denna sida kan du se dina kopplade bankkort både fysiska och virtuella.'
    }
    let hasBankCarsAndOtherCards = '.'
    if (hasBankCards) {
		hasBankCarsAndOtherCards = ' (gäller ej bankkort).'
    }

    return (
		'På denna sida kan du beställa nytt kort, ersättningskort, ändra pinkod med mera. ' +
		'Klicka på punkterna på kortet för att se de olika alternativen du kan göra' +
		hasBankCarsAndOtherCards
    )
}
const { data: customer } = useGetCustomer()
const { customerAllowedToWriteBool } = useCustomerStatus( customer );

return (
    <div className="Section BgColor--Red400">
		<div className="Row Row--JustifyLeft">
			<div className="Row__Inner">
			<div
				className="Column Column--JustifyStart Column--Lg6"
				style={{ marginBottom: 16 }}
			>
				<div className="ContentBox">
				<div className="ContentBox__Content">
					<h1>
						{getHeaderText(hasSmartCards, hasCreditCards, hasBankCards)}
					</h1>
					<p style={{ marginBottom: 24 }}>
						{getInfoText(hasSmartCards, hasCreditCards, hasBankCards)}
					</p>
				</div>
				</div>
				{!isBankIdUser && (
				<>
					<NeedsExtAuth actionText="Om du vill hantera dina Qstar-kort" />
				</>
				)}
				<div>
					{((isBankIdUser && hasSmartCards ) ||
						(isBankIdUser && hasCreditCards)) && (
							<RightsButton
								disableOnFrozenCustomer
								className="PaymentOptions__Badge"
								style={{ margin: 0 }}
								text="Beställ nytt smart-/tank-kort"
								url="/nyttkort"
								type="button"
								userRights="CardsWrite"
								customerAllowedToWrite={customerAllowedToWriteBool}
							/>
						)
					}
				</div>
			</div>
        </div>
	</div>
	<div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
			<div className="Column Column--JustifyStart Column--Lg12">
				<div className="ContentBox">
				<div className="ContentBox__Content">
					{cardsList.length === 0 && !hasBankCards && (
					<>
						<h5 className="CardsPage__NoCardsHeading">
						{'Du har inget Qstar-kort än.'}
						</h5>
						<Button
						text="Ansök online"
						url={
							customerQuery.data?.isCompany
							? Urls.tankkortQstar.foretagskort
							: Urls.tankkortQstar.privatkort
						}
						/>
					</>
					)}
					<>
					<h5>{'Dina kort'}</h5>
					<div className="CardsPage__FilterBar">
						<div className="Cardspage--FilterBarDiv">
						<Label text="Sök kort" />
						<div
							className={classNames(
							'CardsPage__FilterItem CardsPage__SearchBarContainer'
							)}
						>
							<Icon
							className="CardsPage__SearchBarIcon"
							name="search"
							type="line"
							/>
							<input
							className={classNames({
								CardsPage__SearchBar: true,
								'CardsPage__SearchBar--Placeholder': !searchTerm
							})}
							onChange={handleOnChangeSearchInput}
							placeholder="Sök"
							type="search"
							value={searchTerm}
							/>
						</div>
						</div>
						<div className="Cardspage--FilterBarDiv">
						<Label text="Välj kort" />
						<CardsList
							className={classNames(
							'CardsPage__Dropdown',
							'CardsPage__FilterItem'
							)}
							dropDownCardValue={dropDownCardListValue}
							model={cardsList}
							onChangeDropdownCard={handleOnChangeDropdownCard}
							showOldCards={showOldCards}
						/>
						</div>
						<div className="Cardspage--FilterBarDiv">
						<Label text="Korttyp" />
						<select
							className={classNames({
							CardsPage__Dropdown: true,
							CardsPage__FilterItem: true,
							Dropdown: true,
							'Dropdown--Placeholder':
								baseFilterInputs.cardType === 'all',
							'Dropdown--Strong': true
							})}
							name="cardType"
							onBlur={handleBaseFilterChange}
							onChange={handleBaseFilterChange}
							value={baseFilterInputs.cardType}
						>
							<option value="all">{'Välj korttyp'}</option>
							<option value="smartcard">{'Smartkort'}</option>
							<option value="creditcard">{'Tankkort'}</option>
							<option value="bankcard">{'Bankkort'}</option>
						</select>
						</div>
						<div className="Cardspage--FilterBarDiv">
						<Label text="Kortstatus" />
						<select
							className={classNames({
							CardsPage__Dropdown: true,
							CardsPage__FilterItem: true,
							Dropdown: true,
							'Dropdown--Placeholder':
								baseFilterInputs.cardStatus === 'all',
							'Dropdown--Strong': true
							})}
							name="cardStatus"
							onBlur={handleBaseFilterChange}
							onChange={handleBaseFilterChange}
							value={baseFilterInputs.cardStatus}
						>
							<option value="all">{'Välj kortstatus'}</option>
							<option value="active">{'Aktiva kort'}</option>
							<option value="disabled">{'Spärrade kort'}</option>
						</select>
						</div>
						{customerQuery.data?.hasVehicleSpecification ? (
						<>
							<div className="CardsPage--LastFilterBarDiv">
							<Label text="Fordonspecifikation" />
							<select
								className={classNames({
								CardsPage__Dropdown: true,
								CardsPage__FilterItem: true,
								Dropdown: true,
								'Dropdown--Placeholder':
									baseFilterInputs.vehicleSpecification === 'all',
								'Dropdown--Strong': true
								})}
								onBlur={handleBaseFilterChange}
								onChange={handleBaseFilterChange}
								value={baseFilterInputs.vehicleSpecification}
							>
								<option value="all">{'Fordonspecifikation'}</option>
								<option value="active">{'Aktiv'}</option>
								<option value="disabled">{'Inaktiv'}</option>
							</select>
							</div>
						</>
						) : (
						<></>
						)}
					</div>
					<div className="CardsPage__SearchCheckboxRow">
						<div className="CardsPage__DoubleCheckboxes">
						{baseFilterInputs.cardStatus === 'disabled' && (
							<Checkbox
							checked={showOldCards}
							className="CardsPage__CheckBox"
							labelClassName="CardsPage__Label"
							onChange={handleOnChangeFilterOldCard}
							text="Inkludera kort äldre än 3 månader"
							title="Visa även kort som har varit spärrade i mer än 3 månader"
							/>
						)}
						<Checkbox
							checked={showListView}
							className="CardsPage__CheckBox"
							labelClassName="CardsPage__Label"
							onChange={handleOnChangeListView}
							text="Visa som lista"
							title="Visa kort som en lista istället för som ett rutnät"
						/>
						</div>
					</div>
					{showListView ? (
						<CardTable model={filteredCards} />
					) : (
						<CardGrid cards={filteredCards} />
					)}
					{filteredCards.length === 0 && (
						<h5 className="CardsPage__EmptyText">
						{'Sökningen gav inga resultat'}
						</h5>
					)}
					</>
				</div>
				</div>
			</div>
        </div>
	</div>
    </div>
	)
}

export default CardsPage
